









































































































































































import { Component } from 'vue-property-decorator';
import { APPIDS, TABLE_HEADER_CLASS } from '@/enums';
import DialogComponent from '@/components/dialog.component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import CourseProvider from '@/providers/courses.provider';
import { mixins } from 'vue-class-component';
import {
  BasicOption,
  ImportInstructor,
  ImportInstructorResult,
  Rule,
} from '../../types/course.type';
import { Lang } from '../../types/general.type';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    DialogComponent,
    ScopeComponent,
  },
})
export default class InstructorsImport extends mixins(Toaster) {
  appId = APPIDS.COURSES;

  validForm = true;

  loading = false;

  showMissingScopeError = false;

  headerClass = TABLE_HEADER_CLASS;

  get addMethods(): BasicOption[] {
    return [
      { id: '1', name: this.$t('labels.addFromService') },
      { id: '2', name: this.$t('labels.addFromExcel'), disapled: true },
    ];
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
    };
  }

  importSelectedMethod = this.addMethods[0].id;

  $refs!: {
    scopeBtn: ScopeComponent;
  };

  addedInstructors: Array<ImportInstructor> | null = null;

  faildInstractors: Array<ImportInstructor> | null = null;

  succesHeader = [
    {
      text: this.$t('tableHeaders.name'),
      align: 'center',
      sortable: false,
      value: 'name',
      class: this.headerClass,
    },
    {
      text: this.$t('courseName'),
      align: 'center',
      sortable: false,
      value: 'coursesInfo',
      class: this.headerClass,
    },
    {
      text: this.$t('tableHeaders.notes'),
      align: 'center',
      sortable: false,
      value: 'validations',
      class: this.headerClass,
    },
  ];

  masterRule: Rule | null = null;

  serverError: Lang | null = null;

  async mounted() {
    this.getMasterRuleIformation();
  }

  get selectedScope() {
    return this.$store.state.scope.selectedScopeId;
  }

  async getMasterRuleIformation() {
    try {
      if (!this.selectedScope) {
        return;
      }
      this.serverError = null;
      const rule: Rule = await CourseProvider.addInstructorsRule(
        this.appId,
        this.selectedScope,
      );
      this.masterRule = rule;
    } catch (err) {
      this.serverError = err as Lang;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async addCourse() {
    if (!this.selectedScope) {
      this.showMissingScopeError = true;
      this.$refs.scopeBtn.scopeClick();
      ScopeComponent.setFocus();
      return;
    }
    try {
      this.loading = true;
      const addResult: ImportInstructorResult = await CourseProvider.addInstructors(
        this.appId,
        this.selectedScope,
      );
      this.loading = false;
      this.addedInstructors = addResult.added || [];
      this.faildInstractors = addResult.faild || [];
      this.serverError = null;
    } catch (err) {
      this.loading = false;
      this.serverError = err;
      this.addedInstructors = null;
      this.faildInstractors = null;
    }
  }
}
